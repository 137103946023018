// src/components/pages/Status/Status.js
import React, { useState, useEffect, useCallback } from "react";
import io from "socket.io-client";
import "./Dashboard.css";

const fundBrokers = {
    Fund_1: ["MS", "SEB"],
    Fund_2: ["GS", "BAR", "JPM", "SG", "CT"],
    Fund_3: ["MS", "SG", "JPM"],
    Fund_4: ["MS", "SEB", "GS"],
    Fund_5: ["MS", "GS", "SG", "SEB", "JPM"],
    Fund_6: ["MS", "SEB"],
};

const StatusPage = () => {
    const [selectedFund, setSelectedFund] = useState("");
    const [currentTime, setCurrentTime] = useState(new Date());
    const [tableData, setTableData] = useState([]);
    const url = "https://trium-server.azurewebsites.net";

    const [country, dateFormat] = (() => {
        const locale = Intl.DateTimeFormat().resolvedOptions().locale;
        const isUSFormat = ["en-US", "es-US"].includes(locale);
        return isUSFormat ? ["US", "MM/DD/YYYY"] : ["UK", "DD/MM/YYYY"];
    })();

    const formatDate = useCallback((date) => {
        return date.toLocaleDateString([], {
            day: dateFormat === "DD/MM/YYYY" ? "2-digit" : "numeric",
            month: dateFormat === "MM/DD/YYYY" ? "2-digit" : "numeric",
            year: "numeric",
        });
    }, [dateFormat]); // Only changes if dateFormat changes

    // Update the time every minute instead of every second
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000); // 60000 ms = 1 minute
        return () => clearInterval(timer);
    }, [formatDate]);

    const formatTime = (date) => date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    // const formatDate = (date) => date.toLocaleDateString([], {
    //     day: dateFormat === "DD/MM/YYYY" ? "2-digit" : "numeric",
    //     month: dateFormat === "MM/DD/YYYY" ? "2-digit" : "numeric",
    //     year: "numeric",
    // });

    const convertUTCToLocalTime = (utcTime) => new Date(utcTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    const convertUTCToLocalDate = (utcDate) => new Date(utcDate).toLocaleDateString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    useEffect(() => {
        if (!selectedFund) return;
    
        const socket = io.connect(url, {
            query: { token: localStorage.getItem("token") },
        });
    
        socket.on("update_table", (data) => {
            let parsedData;
            try {
                parsedData = typeof data === "string" ? JSON.parse(data) : data;
            } catch (err) {
                console.error("Error parsing update_table data:", err);
                return;
            }
    
            const today = convertUTCToLocalDate(new Date());
            const latestEntries = {};
    
            Object.entries(parsedData).forEach(([timeProcessed, entry]) => {
                if (!Array.isArray(entry) || entry.length < 2) return;
    
                const entryDate = convertUTCToLocalDate(timeProcessed);
                const [broker, fund] = entry;
    
                // Treat TMSU as Fund_5
                const normalizedFund = (fund === "TMSU") ? "Fund_5" : fund;
    
                if (entryDate === today && broker && normalizedFund === selectedFund) {
                    if (!latestEntries[broker] || new Date(timeProcessed) > new Date(latestEntries[broker].timeProcessed)) {
                        latestEntries[broker] = { timeProcessed, status: true };
                    }
                }
            });
    
            const brokersForFund = fundBrokers[selectedFund] || [];
            const finalTableData = brokersForFund.map((broker) => {
                const brokerEntry = latestEntries[broker];
                return {
                    broker,
                    status: brokerEntry ? true : false,
                    timeProcessed: brokerEntry ? convertUTCToLocalTime(brokerEntry.timeProcessed) : "Not Received",
                };
            });
    
            finalTableData.sort((a, b) => a.broker.localeCompare(b.broker));
            setTableData(finalTableData);
        });
    
        return () => socket.disconnect();
    }, [selectedFund, url, formatDate]);

    return (
        <div className="status-page">
            <div className="status-filters">
                <label>
                    Fund:
                    <select
                        value={selectedFund}
                        onChange={(e) => setSelectedFund(e.target.value)}
                    >
                        <option value="">Select Fund</option>
                        {Object.keys(fundBrokers).map((fund) => (
                            <option key={fund} value={fund}>
                                {fund}
                            </option>
                        ))}
                    </select>
                </label>
                <div className="time-display">
                    {country} Time: {formatTime(currentTime)}, {formatDate(currentTime)}
                </div>
            </div>
            <section className="data-table">
                <h2>Document Status</h2>
                {selectedFund ? (
                    <table className="status-table">
                        <thead>
                            <tr>
                                <th>Prime Broker</th>
                                <th>Status</th>
                                <th>Time Received ({country})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.broker}</td>
                                    <td>
                                        {row.status ? (
                                            <span className="status-check">&#10003;</span>
                                        ) : (
                                            <span className="status-cross">&#10007;</span>
                                        )}
                                    </td>
                                    <td>{row.status ? row.timeProcessed : "Not Received"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Please select a fund.</p>
                )}
            </section>
        </div>
    );
};

export default StatusPage;
