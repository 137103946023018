import React, { createContext, useState, useEffect } from "react";

export const FundContext = createContext();

export const FundProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [funds, setFunds] = useState([]);
    const [selectedFund, setSelectedFund] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
    const [filteredData, setFilteredData] = useState([]);
    const [brokersByFund, setBrokersByFund] = useState({});

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await fetch("https://trium-server.azurewebsites.net/api/sql-query", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // body: JSON.stringify({ sql_query: "SELECT * FROM dbo.NAV_Test;" })
                    body: JSON.stringify({ sql_query: "SELECT * FROM dbo.Test;" })
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const jsonData = await response.json();
                setData(jsonData);

                const fundsSet = new Set();
                const brokersMap = {};

                jsonData.forEach(item => {
                    const fund = item.FUND;
                    const broker = item.BROKER;

                    fundsSet.add(fund);

                    if (!brokersMap[fund]) {
                        brokersMap[fund] = new Set();
                    }
                    brokersMap[fund].add(broker);
                });

                setFunds([...fundsSet]);
                setBrokersByFund(
                    Object.fromEntries(
                        Object.entries(brokersMap).map(([fund, brokers]) => [fund, [...brokers]])
                    )
                );
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        if (!selectedFund) {
            setFilteredData([]);
            return;
        }

        const fundBrokers = brokersByFund[selectedFund] || [];
        const filtered = data.filter(
            item => item.FUND === selectedFund && item.DATE === selectedDate
        );

        const dataWithAllBrokers = fundBrokers.map(broker => {
            const brokerData = filtered.find(item => item.BROKER === broker);
            return brokerData || {
                TIME_PROCESSED: "Not Received",
                BROKER: broker,
                MARGIN_REQUIREMENT: null,
                MARGIN_EXCESS: null,
                MARGIN_BALANCE: null,
                PLEDGED_BONDS: null,
                NON_PLEDGED_BONDS: null,
                CASH_BALANCE: null,
                NAV: null,
                CP_EXPOSURE: null,
                LIMIT: null,
                TYPE: null
            };
        });

        setFilteredData(dataWithAllBrokers);
    }, [selectedFund, selectedDate, data, brokersByFund]);

    return (
        <FundContext.Provider value={{ funds, selectedFund, setSelectedFund, selectedDate, setSelectedDate, filteredData }}>
            {children}
        </FundContext.Provider>
    );
};