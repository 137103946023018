// src/components/pages/Requirements/RequirementsPage.js
import React, { useContext } from "react";
import { FundContext, FundProvider } from "./FundContext";
import DataTable from "./DataTable";
import "./RequirementsPage.css";

const RequirementsPage = ({ isSidebarCollapsed }) => {
    const { funds, selectedFund, setSelectedFund, selectedDate, setSelectedDate, filteredData } = useContext(FundContext);

    return (
        <div className="requirements-page">
            <div className="filters">
                <label>
                    Fund:
                    <select
                        value={selectedFund}
                        onChange={(e) => setSelectedFund(e.target.value)}
                    >
                        <option value="">Select Fund</option>
                        {funds.map((fund) => (
                            <option key={fund} value={fund}>
                                {fund}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Date:
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </label>
            </div>
            <section className="data-table">
                <h2>Information Status</h2>
                {selectedFund ? (
                    <DataTable data={filteredData} />
                ) : (
                    <p>Please select a fund.</p>
                )}
            </section>
        </div>
    );
};

const RequirementsPageWrapper = (props) => (
    <FundProvider>
        <RequirementsPage {...props} />
    </FundProvider>
);

export default RequirementsPageWrapper;