// src/components/Pages/Dashboard/DashboardPage.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import '../../../App.css';
import './DocHist.css'
import Login from '../../Login/Login.js';

function DashboardPage() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [lastUpdated, setLastUpdated] = useState('Loading...');
    const [tableData, setTableData] = useState([]);
    const url = 'https://trium-server.azurewebsites.net';

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const token = localStorage.getItem('token');
            const socket = io.connect(url, { query: { token: token } });

            socket.on('connect', () => console.log('Connected to Socket.IO server'));
            socket.on('disconnect', () => console.log('Disconnected from Socket.IO server'));

            socket.on('last_updated', (data) => {
                const isoString = data.last_updated;
                if (isoString) {
                    const date = new Date(isoString);
                    const options = {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                        timeZoneName: 'short'
                    };

                    const formattedDate = date.toLocaleString('en-US', options);                      
                    setLastUpdated(formattedDate);
                } else {
                    setLastUpdated('N/A');
                }
            });

            socket.on('update_table', (data) => {
                console.log('Received update_table data:', data);
                let parsedData;
                try {
                    parsedData = typeof data === 'string' ? JSON.parse(data) : data;
                } catch (err) {
                    console.error('Error parsing update_table data:', err);
                    return;
                }
                const transformedData = [];
        
                Object.entries(parsedData).forEach(([timeProcessed, entry]) => {
                    if (Array.isArray(entry) && entry.length === 3) {
                        const [broker, fund, recordUrl] = entry;
                        transformedData.push({
                            timeProcessed,
                            broker,
                            fund,
                            url: recordUrl,
                        });
                    } else {
                        console.warn(`Unexpected entry format for ${timeProcessed}:`, entry);
                    }
                });
                transformedData.sort((a, b) => new Date(b.timeProcessed) - new Date(a.timeProcessed));
                console.log('Transformed and sorted tableData:', transformedData);
                setTableData(transformedData);
            });
        
            return () => {
                socket.disconnect();
            };
        }
    }, [isAuthenticated, url]);

    const handleDownload = () => {
        const token = localStorage.getItem('token');
        window.location.href = `${url}/download-excel?token=${token}`;
    };

    return (
        <div className="App">
            {isAuthenticated ? (
                <>
                    <h1>Document History</h1>
                    <div className="update-info">
                        Last updated: <span className="last-updated">{lastUpdated}</span>
                    </div>
                    <button className="download-button" onClick={handleDownload}>Download Latest Data</button>
                    <table className="data-table">
                        <thead>
                            <tr><th>Time Processed</th><th>Broker</th><th>Fund</th><th>URL</th></tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>{new Date(row.timeProcessed).toLocaleString('en-US', {
                                        year: 'numeric', month: '2-digit', day: '2-digit',
                                        hour: 'numeric', minute: '2-digit', second: '2-digit',
                                        hour12: true, timeZoneName: 'short'
                                    })}</td>
                                    <td>{row.broker}</td>
                                    <td>{row.fund}</td>
                                    <td>{row.url ? <a href={row.url} target="_blank" rel="noopener noreferrer">Link</a> : 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <Login setIsAuthenticated={setIsAuthenticated} />
            )}
        </div>
    );
}

export default DashboardPage;
