import React, { useContext } from "react";
import { FundContext } from "./FundContext";
import "./DataTable.css";

const DataTable = () => {
    const { filteredData } = useContext(FundContext);

    // Function to format the date in a more readable way
    const formatDateTime = (dateString) => {
        if (dateString === "Not Received"){
            return "N/A";
        }
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
    };
    

    return (
        <div className="table-wrapper">
            <table className="single-table">
                <thead>
                    <tr>
                        <th>TIME_RECEIVED</th>
                        <th>BROKER</th>
                        <th>MARGIN_REQ</th>
                        <th>MARGIN_EXC</th>
                        <th>MARGIN_BAL</th>
                        <th>PB</th>
                        <th>NON_PB</th>
                        <th>CASH_BAL</th>
                        <th>NAV</th>
                        <th>CP_EXP</th>
                        <th>LIMIT</th>
                        <th>On Call</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={index}>
                            <td>{formatDateTime(item.TIME_PROCESSED)}</td>
                            <td>{item.TYPE ? `${item.BROKER} (${item.TYPE})` : item.BROKER}</td>
                            <td>{item.MARGIN_REQUIREMENT ? Number(item.MARGIN_REQUIREMENT).toFixed(2) : ""}</td>
                            <td>{item.MARGIN_EXCESS ? Number(item.MARGIN_EXCESS).toFixed(2) : ""}</td>
                            <td>{item.MARGIN_BALANCE ? Number(item.MARGIN_BALANCE).toFixed(2) : ""}</td>
                            <td>{item.PLEDGED_BONDS ? Number(item.PLEDGED_BONDS).toFixed(2) : ""}</td>
                            <td>{item.NON_PLEDGED_BONDS ? Number(item.NON_PLEDGED_BONDS).toFixed(2) : ""}</td>
                            <td>{item.CASH_BALANCE ? Number(item.CASH_BALANCE).toFixed(2) : ""}</td>
                            <td>{item.NAV ? Number(item.NAV).toFixed(2) : ""}</td>
                            <td>{item.CP_EXPOSURE ? Number(item.CP_EXPOSURE).toFixed(2) : ""}</td>
                            <td>{item.LIMIT ? Number(item.LIMIT).toFixed(2) : ""}</td>
                            <td><input type="checkbox" checked={Number(item.CP_EXPOSURE) > Number(item.LIMIT)} readOnly /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;