// src/components/pages/Historicals/HistoricalsPage.js
import React from "react";
import { HistoricalDataProvider } from "./HistoricalsDataContext";
import HistoricalsPageContent from "./HistoricalsPageContent"; // Separate main content

const HistoricalsPage = () => (
    <HistoricalDataProvider>
        <HistoricalsPageContent />
    </HistoricalDataProvider>
);

export default HistoricalsPage;