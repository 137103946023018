import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

function Login({ setIsAuthenticated }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const url = 'https://trium-server.azurewebsites.net';

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${url}/login`, { username, password })
            .then((response) => {
                if (response.data.status === 'success') {
                    const token = response.data.token;
                    localStorage.setItem('token', token);
                    setIsAuthenticated(true);
                } else {
                    alert('Invalid credentials');
                }
            })
            .catch((error) => {
                alert('Error logging in. Please check your credentials.');
            });
    };

    const handleClose = () => {
        navigate('/');
    };

    return (
        <div className="login-container">
            <button className="close-button" onClick={handleClose}>x</button>
            <h2>stag</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="login-input"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    className="login-input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="login-button">Log in</button>
            </form>
        </div>
    );
}

export default Login;