import React, { createContext, useState, useEffect } from "react";

export const HistoricalDataContext = createContext();

export const HistoricalDataProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [funds, setFunds] = useState([]);
    const [selectedFund, setSelectedFund] = useState("");
    const [historicalData, setHistoricalData] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await fetch("https://trium-server.azurewebsites.net/api/sql-query", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // body: JSON.stringify({ sql_query: "SELECT * FROM dbo.NAV_Test;" })
                    body: JSON.stringify({ sql_query: "SELECT * FROM dbo.Test;" })
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const jsonData = await response.json();
                console.log(jsonData)
                setData(jsonData);

                const uniqueFunds = [...new Set(jsonData.map(item => item.FUND))];
                setFunds(uniqueFunds);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        if (!selectedFund) {
            setHistoricalData([]);
            return;
        }

        const processedData = data
            .filter(item => item.FUND === selectedFund)
            .reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.date === item.DATE);
                
                if (existingEntry) {
                    existingEntry.cpExposure = item.CP_EXPOSURE || existingEntry.cpExposure;
                    existingEntry.limit = item.LIMIT || existingEntry.limit;
                    existingEntry.marginExcess = item.MARGIN_EXCESS || existingEntry.marginExcess;
                    existingEntry.marginRequirement = item.MARGIN_REQUIREMENT || existingEntry.marginRequirement;
                    existingEntry.marginBalance = item.MARGIN_BALANCE || existingEntry.marginBalance;
                } else {
                    acc.push({
                        date: item.DATE,
                        cpExposure: item.CP_EXPOSURE || null,
                        limit: item.LIMIT || null,
                        marginExcess: item.MARGIN_EXCESS || null,
                        marginRequirement: item.MARGIN_REQUIREMENT || null,
                        marginBalance: item.MARGIN_BALANCE || null,
                    });
                }
                return acc;
            }, [])
            .sort((a, b) => new Date(a.date) - new Date(b.date));

        setHistoricalData(processedData);
    }, [selectedFund, data]);

    return (
        <HistoricalDataContext.Provider value={{ funds, selectedFund, setSelectedFund, historicalData }}>
            {children}
        </HistoricalDataContext.Provider>
    );
};