// src/components/Pages/LandingPage/LandingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login');
    };

    return (
        <div className="landing-page">
            <div className="stag-logo">stag</div>
            <div className="main-message">
                <h1 className="coming-soon">Coming Soon</h1>
                <p className="automation-message">Automating the treasury management<br/>process for fund managers</p>
                <a href="https://calendly.com/sinclair-usestag/30min" target="_blank" rel="noopener noreferrer" className="lets-talk-button">Let’s Talk</a>
            </div>
            <button className="investor-login" onClick={handleLoginClick}>
                Login
            </button>
        </div>
    );
};

export default LandingPage;